<!--
<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="subindex_wrap pt-50">
			<div class="container">
				<div class="subindex_mainbox type2">

					<h3>{{ program.title }}</h3>
					<div>
						<p class="step_tit">① 휴대폰번호 입력하세요.</p>

						<div class="bright_input flex ">
							<input
									v-model="item.mphone_number"
									type="text"
									placeholder="휴대폰 번호"
									:rules="[$rules.numeric(item, 'mphone_number', 11)]"
									:disabled="is_number"
							>
							<button
									class="btn_l btn_fill_blue ml-10 flex_shrink"
									:disabled="is_send"

									@click="postRequest"
							>요청</button>
						</div>
					</div>

					<div>
						<p class="step_tit">② 인증번호를 입력하세요.</p>
						<div class="bright_input">
							<input
									v-model="item.certfc_number"
									maxlength="6"
									type="text" placeholder="6자리 인증번호 입력"
									:rules="[$rules.numeric(item, 'certfc_number', 6)]"
									:disabled="is_code"
							>
						</div>
					</div>

					<div class="btn_area">
						<button
								class="btn_l btn_fill_blue"
								:disabled="item.certfc_number.length != 6"

								@click="postConfirm"
						>확인</button>
					</div>

				</div>
			</div>
		</div>
		<PIN
				v-if="is_pin"
				:options="pin_option"
				style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999999"

				@click="pinClick"
				@cancel="pinCancel"
		></PIN>
	</div>
</template>

-->

<template>
	<div class="bg-mafia-dark flex-column position-relative">
		<div class="popup" v-if="!isConfirmSms">
			<SMS_POPUP
				@request="postRequest"
				@confirm="postConfirm"
				@cancel="() => {
					$router.replace('login')
					$emit('offPopup')
				}"
			></SMS_POPUP>
		</div>
		<PIN
			v-if="is_pin"
			:options="pin_option"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999999"

			@click="pinClick"
			@cancel="pinCancel"
		></PIN>
	</div>
</template>


<script>

import PIN from "@/view/Auth/mafia007";
import SMS_POPUP from "@/view/Auth/smsPopup";

export default {
	name: 'sms'
	, components: {PIN, SMS_POPUP}
	, data: function () {
		return {
			program: {
				name: 'sms'
				, title: '휴대폰 본인 인증'
				, not_header: true
				, not_footer: true
				//, type: 'mypage'
				, type: 'auth'
			}
			, is_pin: false
			, pin_option: {
				pin_type: 'set'
			}
			, item: {
				mphone_number: ''
				, certfc_request_key: ''
				, certfc_number: ''
			}
			, nickname: ''
			, member_number: ''
			, isConfirmSms: false	// SMS 인증이 정상적으로 완료되었는가?
		}
	}

	, computed: {
		is_send: function () {
			let t = true
			if (!this.item.certfc_request_key && this.item.mphone_number.length == 11) {
				t = false
			}

			return t
		}
		, is_number: function () {
			let t = true
			if (this.item.certfc_request_key == '') {
				t = false
			}
			return t
		}
		, is_code: function () {
			let t = true
			if (this.item.certfc_request_key && this.item.mphone_number.length == 11) {
				t = false
			}
			return t
		}
	}
	, methods: {
		postOtp: async function () {
			this.$log.console('postOtp')
			try {
				this.$bus.$emit('on', true)
				if (!this.certfc_number) {
					throw 'OTP 번호를 입력하세요'
				}
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_google_otp
					, data: {
						certfc_number: this.certfc_number
					}
					, type: true
				})

				if (result.success) {
					this.is_pin = true
				} else {
					throw result.message
				}

			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, pinClick: async function () {
			this.pin_option.pin_type = 'set'
			this.$emit('to', {name: 'mafia010'})
		}
		, pinCancel: function () {
			this.$log.console('cancel')
			this.is_pin = false
			this.pin_option.pin_type = 'set'
		}
		, getGoogleOtpKey: async function () {
			this.$log.console('getGoogleOtpKey')
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_google_otp_key
					, data: {}
					, type: true
				})

				if (result.success) {
					this.$log.console(result.data)
					this.item.mphone_number = result.data.mphone_number
					this.item.nickname = result.data.nickname
					this.item.member_number = result.data.member_number
				} else {
					throw result.message
				}

			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
				this.$bus.$emit('to', {name: 'join'})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, clipBoard: function (val) {
			const t = document.createElement("textarea");
			document.body.appendChild(t);

			t.value = val;
			this.$log.console('t.value', t.value)
			t.select();
			t.setSelectionRange(0, 9999)
			document.execCommand('copy');
			document.body.removeChild(t);
		}
		, postRequest: async function (phone = '') {
			try {
				if (phone) {
					this.item.mphone_number = phone
				}

				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_sms_request
					, data: {
						mphone_number: this.item.mphone_number
					}
					, type: true
				})

				if (result.success) {
					this.$log.console(result.data)
					this.item.certfc_request_key = result.data.certfc_request_key
				} else {
					throw result.message
				}

			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, postConfirm: async function (verifyCode = '') {
			try {
				if (verifyCode) {
					this.item.certfc_number = verifyCode
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_sms_confirm
					, data: {
						mphone_number: this.item.mphone_number
						, certfc_request_key: this.item.certfc_request_key
						, certfc_number: this.item.certfc_number
					}
					, type: true
				})

				if (result.success) {

					if(result.data.session_token){
						await this.$encodeStorage.setSessionToken(result.data.session_token)
					}
					this.isConfirmSms = true
					this.$emit('offPopup')	

					this.$bus.$emit('onPin', 'set')
					this.$bus.$on('pinCallback', () => {
						this.$emit('to', {name: 'mafia010'})
						this.$bus.$off('pinCallback')
					})
					this.$bus.$on('pinCancel', () => {
						this.item = {
							mphone_number: ''
							, certfc_request_key: ''
							, certfc_number: ''
						}
						this.$bus.$off('pinCancel')
					})
				} else {
					throw result.message
				}

			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$bus.$emit('onLoad', this.program)
		this.$emit('onPopup')
	}
}
</script>
<style>
.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 500px;
	height: 700px;
	box-shadow: 7px 7px 5px rgba(0, 0, 0, 0.2);
	z-index: 100000;
}
</style>